import { ReactComponent as SVG } from 'images/card-decor.svg';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-parallax';

const parallaxValue = 50;

const ProductCard = ({ data, index }) => {
    const {
        preview_title,
        preview_text,
        preview_link_text,
        preview_image,
        preview_new_image,
        slug,
        logo,
        preview_description,
        preview_button_text,
    } = data;

    const number = String(index + 1).padStart(2, '0');
    const description = preview_text || preview_description;
    const button_text = preview_link_text || preview_button_text;

    return (
        <Link to={slug} className={`product-card ${index % 2 !== 0 ? 'product-card--reverse' : ''}`}>
            <div className="product-card__image" data-index={'• ' + number}>
                <SVG />
                <Parallax
                    bgImage={preview_image || preview_new_image}
                    className="product-card__image-bg"
                    strength={parallaxValue}
                    bgImageStyle={{
                        height: `calc(100% + ${parallaxValue}px)`,
                        objectFit: 'cover',
                    }}
                />
                {logo && (
                    <div className="product-card__image-logo">
                        <img src={logo} alt="product-logo" />
                    </div>
                )}
            </div>
            <div className="product-card__content">
                <h3>{preview_title}</h3>
                <p className="body1">{description}</p>
                <button className="button learn-more-arrow has-children">
                    <div></div>
                    <span>{button_text}</span>
                </button>
            </div>
        </Link>
    );
};

export default ProductCard;
